<template>
  <Modal
    width="1400"
    scrollable
    loading
    class-name="i-modal"
    v-model="isShow"
    :title="isEdit ? '编辑商品' : isAdd ? '新增商品' : '查看商品'"
  >
    <Row :gutter="12">
      <Form
        :model="obj"
        ref="ProductForm"
        :rules="ruleForm"
        class="modelViewFromCheck"
        style="width: 100%"
        :label-width="120"
      >
        <Row>
          <Col span="12">
            <div style="padding-left: 10px">
              <Row :gutter="8">
                <div id="fauplode" v-if="isEdit || isAdd">
                  <FormItem prop="ID" label="商品图片:">
                    <RadioGroup
                      v-model="DefaultImagePath"
                      @on-change="RadioChange"
                    >
                      <ul class="product-img-upload">
                        <li
                          span="1"
                          class="img-box"
                          v-for="img in files"
                          :key="img._key"
                        >
                          <div
                            class="file-item-cover"
                            @click="viewPic(img._file)"
                          >
                            <Icon
                              type="ios-close-circle-outline"
                              size="24"
                              @click.native.stop="doRemove(img)"
                              color="#ED1C24"
                            ></Icon>
                          </div>
                          <img :src="img._file" />
                          <InputNumber
                            size="small"
                            :max="50"
                            :min="1"
                            v-model="img.Index"
                          ></InputNumber>
                          <Radio size="small" :label="img._key">
                            设为默认
                          </Radio>
                        </li>
                        <i-upload
                          :show-img="false"
                          :fileKey="UploadType.Product"
                          multiple
                          :numNow="
                            obj.ProductImages ? obj.ProductImages.length : 0
                          "
                          @onChange="onChange"
                          ref="UploadProduct"
                        ></i-upload>
                      </ul>
                    </RadioGroup>
                  </FormItem>
                </div>
              </Row>
            </div>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="8">
            <FormItem prop="ID" label="商品ID:">
              <Input v-model="obj.ID" disabled />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Code" label="商品编号:">
              <Input
                v-model="obj.Code"
                placeholder="请输入商品编号"
                :disabled="!isEdit && !isAdd"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Name" label="商品名称:">
              <Input
                v-model="obj.Name"
                placeholder="请输入商品名称"
                :disabled="!isEdit && !isAdd"
              />
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="8">
            <FormItem prop="BrandID" label="商品品牌:">
              <Select
                clearable
                v-model="obj.BrandID"
                @on-change="brandSelectChange"
                placeholder="商品品牌"
                :disabled="!isEdit && !isAdd"
              >
                <Option
                  v-for="(item, index) in ProductBrandInfo"
                  :key="index"
                  :style="{ marginLeft: item.Layer * 20 + 'px' }"
                  :value="item.ID"
                >
                  {{ item.Name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="ClassCode" label="商品分类:">
              <Select
                clearable
                v-model="ClassCode"
                placeholder="商品分类"
                multiple
                :disabled="!isEdit && !isAdd"
              >
                <Option
                  v-for="(item, index) in modelNewClass"
                  :key="index"
                  :value="item.Code"
                >
                  {{ item.Name }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="ShowSells" label="展示销量:">
              <Input
                :disabled="!isEdit && !isAdd"
                v-model="obj.ShowSells"
                placeholder="请输入商品展示销量"
              />
            </FormItem>
          </Col>
          <!-- <Col span="8">
            <FormItem prop="Model" label="商品规格:">
              <Input
                :disabled="!isEdit && !isAdd"
                v-model="obj.Model"
                placeholder="请输入商品规格"
              />
            </FormItem>
          </Col> -->
        </Row>
        <Row :gutter="16">
          <Col span="8">
            <FormItem prop="Price" label="销售价:">
              <Input
                :disabled="!isEdit && !isAdd"
                type="text"
                placeholder="大于0的数字，支持2位小数"
                v-model="obj.Price"
                @input="onInputPrice"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="InsidePrice" label="内部价:">
              <Input
                :disabled="!isEdit && !isAdd"
                type="text"
                placeholder="大于0的数字，支持2位小数"
                v-model="obj.InsidePrice"
                @input="onInputPrice"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="PurchasePrice" label="成本价:">
              <Input
                :disabled="!isEdit && !isAdd"
                type="text"
                placeholder="大于等于0的数字，支持2位小数"
                v-model="obj.PurchasePrice"
              ></Input>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="8">
            <FormItem prop="Bonus1" label="一级提成:">
              <Input
                :disabled="!isEdit && !isAdd"
                type="text"
                placeholder="大于0的数字，支持2位小数"
                v-model="obj.Bonus1"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Bonus2" label="二级提成:">
              <Input
                :disabled="!isEdit && !isAdd"
                type="text"
                placeholder="大于等于0的数字，支持2位小数"
                v-model="obj.Bonus2"
              ></Input>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="ShowSells" label="展示销量:">
              <Input
                :disabled="!isEdit && !isAdd"
                v-model="obj.ShowSells"
                placeholder="请输入商品展示销量"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Stock" label="商品库存:">
              <Input
                :disabled="!isEdit && !isAdd"
                v-model="obj.Stock"
                placeholder="大于等于0的数字"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Unit" label="商品单位:">
              <Input
                :disabled="!isEdit && !isAdd"
                v-model="obj.Unit"
                placeholder="请输入商品单位"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Label" label="商品标签:">
              <Input
                :disabled="!isEdit && !isAdd"
                v-model="obj.Label"
                placeholder="限4个字符"
              />
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Status" label="商品状态:">
              <Select
                :disabled="!isEdit && !isAdd"
                clearable
                v-model="obj.Status"
                placeholder="商品状态"
              >
                <Option
                  v-for="(item, index) in ProductStatusEnum"
                  :key="index"
                  :value="item.ID"
                >
                  {{ item.CN }}
                </Option>
              </Select>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="最小购买" prop="MinNum">
              <InputNumber
                type="text"
                v-model="obj.MinNum"
                placeholder="自然数,0表示不限制"
                style="width: 100%"
              ></InputNumber>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem label="最大购买">
              <InputNumber
                type="text"
                placeholder="自然数,0表示不限制"
                v-model="obj.MaxNum"
                style="width: 100%"
              ></InputNumber>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="IsSellOut" label="是否售罄:">
              <Checkbox v-model="obj.IsSellOut">售罄</Checkbox>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="SaleBeginDate" label="上架时间:">
              <DatePicker
                type="datetime"
                @on-change="obj.SaleBeginDate = $event"
                :value="obj.SaleBeginDate"
                style="width: 100%"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="SaleEndDate" label="下架时间:">
              <DatePicker
                type="datetime"
                @on-change="obj.SaleEndDate = $event"
                :value="obj.SaleEndDate"
                style="width: 100%"
              ></DatePicker>
            </FormItem>
          </Col>
          <Col span="8">
            <FormItem prop="Topic" label="商品栏目:">
              <CheckboxGroup v-model="obj.Topic">
                <Checkbox
                  :label="String(item.ID)"
                  v-for="item in ProductTopicEnum"
                  :key="item.ID"
                  :disabled="!isEdit && !isAdd"
                >
                  {{ item.CN }}
                </Checkbox>
              </CheckboxGroup>
            </FormItem>
          </Col>
        </Row>
        <Row :gutter="16">
          <Col span="24">
            <FormItem prop="ShareDescription" label="商品分享描述:">
              <Input
                :disabled="!isEdit && !isAdd"
                type="textarea"
                v-model="obj.ShareDescription"
              />
            </FormItem>
          </Col>
        </Row>
        <!-- 商品规格 start -->
        <Divider orientation="left">商品规格</Divider>
        <Button type="primary" @click="addSubProduct">添加</Button>
        <Table
          style="margin-top: 10px"
          border
          :columns="[
            { title: '商品规格', slot: 'Model' },
            { title: '库存', slot: 'Stock' },
            { title: '操作', slot: 'action', width: 80 },
          ]"
          :data="obj.SubProductList"
        >
          <template #Model="{ row, index }">
            <Input
              placeholder="请输入商品规格"
              :value="row.Model"
              @input="handleModelInput($event, index)"
            />
          </template>
          <template #Stock="{ row, index }">
            <InputNumber
              style="width: 100%"
              :min="0"
              :value="row.Stock"
              @input="handleStockInput($event, index)"
            />
          </template>
          <template #action="{ row, index }">
            <Button type="error" @click="onDelSubProduct(row, index)"
              >删除</Button
            >
          </template>
        </Table>
        <!-- 商品规格 end -->
        <Row>
          <Col span="24">
            <div v-if="isAdd || isEdit" class="tiny-parent">
              <Divider orientation="left">
                <span style="color: red; font-size: 12px">*</span>
                商品详情
              </Divider>
              <tinymce-editor id="tinydes" ref="tinydes" />
            </div>
          </Col>
        </Row>
      </Form>
    </Row>
    <div slot="footer">
      <Button @click="isShow = false">取消</Button>
      <Button
        type="primary"
        v-if="isEdit || isAdd"
        @click="handleSave"
        :loading="saveLoading"
      >
        保存
      </Button>
    </div>
    <Modal
      title="提示"
      @on-cancel="cancelCheck"
      v-model="CheckModel"
      class-name="i-modal"
    >
      <div>
        {{ CheckRes }}
      </div>
      <div slot="footer">
        <Button @click="cancelCheck">取消</Button>
        <Button
          type="primary"
          v-if="(isEdit || isAdd) && CheckRes.indexOf('？') > -1"
          @click="SubmitSave"
          :loading="CheckLoading"
        >
          确认修改
        </Button>
      </div>
    </Modal>
    <Modal
      title="查看大图"
      class-name="i-modal"
      width="400"
      v-model="isShowViewImage"
      :footer-hide="true"
    >
      <img :src="viewImg" v-if="isShowViewImage" style="width: 100%" />
      <template slot="footer">
        <Button @click="isShowViewImage = false">关闭</Button>
      </template>
    </Modal>
  </Modal>
</template>

<script>
import api from "@/api";
import { mapActions } from "vuex";
import bizEnum from "@/libs/bizEnum";
import IUpload from "@/components/upload/index.vue";
import TinymceEditor from "@/components/tinymce-editor/index.vue";
import dayjs from "dayjs";
import BigJS from "@/utils/big";
import Omit from "lodash/omit";
export default {
  name: "add-product-modal",
  data() {
    const validatePositive = (rule, value, callback) => {
      if (value && isNaN(Number(value))) {
        callback(new Error("请输入数字"));
      } else if (value < 0) {
        callback(new Error("数值不能小于0"));
      } else {
        callback();
      }
    };
    // const validatePositiveOneDot = (rule, value, callback) => {
    //   if (value && isNaN(Number(value))) {
    //     callback(new Error("请输入数字"));
    //   } else if (value < 0) {
    //     callback(new Error("数值不能小于0"));
    //   } else if (
    //     value &&
    //     String(value).indexOf(".") > 0 &&
    //     String(value).split(".")[1].length > 1
    //   ) {
    //     callback(new Error("只支持一位小数"));
    //   } else {
    //     callback();
    //   }
    // };
    const validatePositveInt = (rule, value, callback) => {
      if (!isNaN(value)) {
        if (isNaN(Number(value))) {
          callback(new Error("请输入正整数"));
        } else if (value < 0) {
          callback(new Error("数值不能小于0"));
        } else if (Math.floor(value) !== Number(value)) {
          callback(new Error("请输入正整数"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    return {
      isShowViewImage: false,
      viewImg: "",
      isShow: false,
      isEdit: false,
      tableHeight: 0,
      copyID: null,
      isAdd: true,
      saveLoading: false,
      loading: false,
      CheckModel: false,
      CheckRes: "",
      CheckLoading: false,
      oldProductType: "",
      files: [],
      DefaultImagePath: "",
      ClassCode: [],
      // CheckBonusStr: "",
      obj: {},
      modelNewClass: [],
      checkProductList: [],
      ruleForm: {
        Code: [
          { required: true, message: "商品编号不能为空", trigger: "blur" },
        ],
        Name: [
          { required: true, message: "商品名称不能为空", trigger: "blur" },
        ],
        Summary: [
          { required: true, message: "商品详细描述不能为空", trigger: "blur" },
        ],
        // Model: [
        //   { required: true, message: "商品规格不能为空", trigger: "blur" },
        // ],
        Price: [
          { required: true, message: "销售价不能为空" },
          { validator: validatePositive, trigger: "blur" },
        ],
        InsidePrice: [
          { required: true, message: "内部价不能为空" },
          { validator: validatePositive, trigger: "blur" },
        ],
        Bonus1: [
          { required: true, message: "一级提成不能为空" },
          { validator: validatePositive, trigger: "blur" },
        ],
        Bonus2: [
          { required: true, message: "二级提成不能为空" },
          { validator: validatePositive, trigger: "blur" },
        ],
        PurchasePrice: [
          { required: true, message: "销售价不能为空" },
          { validator: validatePositive, trigger: "blur" },
        ],
        Stock: [
          { required: true, message: "商品库存不能为空" },
          { validator: validatePositveInt, trigger: "blur" },
        ],
        MinNum: [
          { required: true, message: "商品库存不能为空" },
          { validator: validatePositveInt, trigger: "blur" },
        ],
        BrandID: [{ required: true, message: "商品品牌不能为空" }],
        ClassCode: [
          { required: true, message: "商品分类不能为空", trigger: "blur" },
        ],
        Status: [{ required: true, message: "商品状态不能为空" }],
      },
    };
  },
  created() {
    this.getProductBrandInfo();
    this.getProductClassInfo();
  },
  watch: {
    isShow: function (val) {
      if (val) {
        this.modelNewClass = this.ProductClassInfo;
        this.obj.IsChild = this.obj.IsChild ? this.obj.IsChild : false;
        this.ClassCode = this.obj.ClassCode
          ? this.obj.ClassCode.split(",")
          : [];
        this.oldProductType = this.obj.ProductType;
        if (this.obj.ProductMedia && this.obj.ProductMedia.length !== 0) {
          let files = this.obj.ProductMedia.filter((i) => i.Type === 10).map(
            (item) => {
              return {
                _key: item.ImagePath,
                _file: this.$setImagePrefix(item.ImagePath),
                success: true,
                Index: item.Index,
                IsDefault: item.IsDefault || false,
              };
            }
          );
          if (files.length === 1) {
            files[0].IsDefault = true;
            this.DefaultImagePath = files[0]._key;
          } else {
            this.DefaultImagePath = this.obj.DefaultImagePath;
          }
          if (this.Topic == null) this.Topic = [];
          this.$refs.UploadProduct.uploadFiles = files;
          this.files = JSON.parse(JSON.stringify(files));
        } else {
          this.files = [];
          this.$refs.UploadProduct.uploadFiles = [];
        }
        // if (this.obj.CheckBonus !== null) {
        //   this.CheckBonusStr = this.obj.CheckBonus ? 1 : 0;
        // }
        if (this.obj.Service) {
          this.obj.Service = this.obj.Service.split(",").map((i) => Number(i));
        }
        if (this.obj.Limit) {
          this.obj.Limit = this.obj.Limit.split(",").map((i) => Number(i));
        }
        if (
          this.obj.ProductContent &&
          this.obj.ProductContent.findIndex((c) => c.Type === 10) >= 0
        ) {
          this.$refs.tinydes.tinymceHtml = this.obj.ProductContent.find(
            (i) => i.Type === 10
          ).Content;
        } else {
          this.$refs.tinydes.tinymceHtml = "";
        }
        if (this.obj.ID || this.copyID) {
          this.brandSelectChange(this.obj.BrandID, true);
        } else {
          this.modelNewClass = [];
        }
      } else {
        this.ClassCode = [];
      }
    },
  },
  methods: {
    ...mapActions(["getProductBrandInfo", "getProductClassInfo"]),
    onInputPrice() {
      if (
        !isNaN(Number(this.obj.Price)) &&
        !isNaN(Number(this.obj.InsidePrice))
      ) {
        this.$set(
          this.obj,
          "Bonus1",
          BigJS.div(
            BigJS.minus(Number(this.obj.Price), Number(this.obj.InsidePrice)) >
              0
              ? BigJS.minus(
                  Number(this.obj.Price),
                  Number(this.obj.InsidePrice)
                )
              : 0,
            2
          ) * 1
        );
        this.$set(
          this.obj,
          "Bonus2",
          BigJS.div(
            BigJS.minus(Number(this.obj.Price), Number(this.obj.InsidePrice)) >
              0
              ? BigJS.minus(
                  Number(this.obj.Price),
                  Number(this.obj.InsidePrice)
                )
              : 0,
            2
          ) * 1
        );
      }
    },
    cancelCheck() {
      this.CheckModel = false;
      this.saveLoading = false;
    },
    viewPic(src) {
      this.viewImg = src;
      this.isShowViewImage = true;
    },
    onChange(opt) {
      const _this = this;
      if (opt.files.length > this.files.length) {
        let pro = 1;
        opt.files.map((item) => {
          if (_this.files.findIndex((i) => i._key === item._key) < 0) {
            item.Index = _this.files.length + pro;
            pro++;
          }
        });
      }
      this.files = [];
      opt.files.forEach((item) => {
        this.files.push(item);
      });
      if (this.files.length === 1) {
        this.files[0].IsDefault = true;
        this.DefaultImagePath = this.files[0]._key;
      }
      this.$refs.UploadProduct.uploadFiles = opt.files;
    },
    doRemove(item) {
      this.files.splice(
        this.files.findIndex((i) => i._key === item._key),
        1
      );
      this.$refs.UploadProduct.uploadFiles.splice(
        this.$refs.UploadProduct.uploadFiles.findIndex(
          (i) => i._key === item._key
        ),
        1
      );
    },
    // 单选改变
    RadioChange(ID) {
      const _this = this;
      _this.obj.ProductMedia.map((item) => {
        item.IsDefault = item.ImagePath === ID;
      });
      _this.files.map((item) => {
        item.IsDefault = item._key === ID;
        if (item.IsDefault === true) {
          _this.obj.ProductMedia.map((cell) => {
            cell.IsDefault = false;
          });
        }
      });
      _this.obj.s = ID;
    },
    handleSave() {
      const _this = this;
      if (this.files.length <= 0) {
        _this.$Message.error("请上传商品图片");
        return;
      }
      _this.obj.ClassCode = _this.ClassCode.join(",");
      _this.$refs.ProductForm.validate(async (valid) => {
        if (valid) {
          try {
            if (_this.obj.Unit && _this.obj.Unit.length > 2) {
              _this.$Message.error("商品单位不能多于两位");
              _this.saveLoading = false;
              return false;
            }
            if (_this.obj.Label && _this.obj.Label.length > 4) {
              _this.$Message.error("商品标签不能多于4个字");
              _this.saveLoading = false;
              return false;
            }
            if (!this.obj.Topic || _this.obj.Topic.length === 0) {
              _this.$Message.error("商品栏目不能为空");
              _this.saveLoading = false;
              return false;
            }
            if (_this.files.length === 0) {
              return _this.$Message.warning("请上传图标");
            }
            _this.saveLoading = true;
            _this.obj.ProductMedia = [];
            const res = await _this.$refs.UploadProduct.Upload();
            if (res.success.length === _this.files.length) {
              _this.obj.ProductMedia = _this.obj.ProductMedia || [];
              _this.files.forEach((item) => {
                _this.obj.ProductMedia.push({
                  ImagePath: item._key,
                  ProductID: _this.obj.ID,
                  CreateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
                  Index: item.Index,
                  IsDefault: item.IsDefault,
                  Type: 10,
                });
              });
              const defaultImages = _this.obj.ProductMedia.filter(
                (item) => item.IsDefault
              );
              if (!defaultImages || defaultImages.length !== 1) {
                _this.$Message.error("请选择默认图片");
                _this.saveLoading = false;
                return;
              }
              _this.obj.DefaultImagePath = defaultImages[0].ImagePath;
              let desc = { ProductID: _this.obj.ID, Type: 10, Content: "" };
              if (
                _this.obj.ProductContent &&
                _this.obj.ProductContent.findIndex((c) => c.Type === 10) >= 0
              ) {
                desc = _this.obj.ProductContent.find((i) => i.Type === 10);
              }
              if (
                _this.$refs.tinydes.tinymceHtml === null ||
                _this.$refs.tinydes.tinymceHtml.length <= 0
              ) {
                _this.$Message.error("请填写商品描述");
                _this.saveLoading = false;
                return;
              }
              desc.Content = _this.$refs.tinydes.tinymceHtml;
              _this.obj.ProductContent = [desc];
              if (
                _this.obj.ID &&
                _this.oldProductType &&
                _this.obj.ProductType !== _this.oldProductType
              ) {
                if (_this.obj.ProductType === 20) {
                  const res1 = await api.BeforeSaveProduct(_this.obj);
                  if (res1.Data === false) {
                    _this.CheckModel = true;
                    _this.CheckRes = res1.Msg;
                  } else {
                    _this.CheckModel = true;
                    _this.CheckRes =
                      "商品属性已修改为组合商品，该商品库存将自动清0，确认是否修改？";
                  }
                }
                if (_this.obj.ProductType === 10) {
                  _this.CheckModel = true;
                  _this.CheckRes =
                    "商品属性已修改为普通商品，该商品库存将自动清0，确认是否修改？";
                }
              } else {
                _this.SubmitSave();
              }
            }
          } catch (error) {
            this.$Notice.error({
              title: "错误",
              desc: error,
            });
            _this.saveLoading = false;
          }
        } else {
          _this.$Message.error("填写信息有误！");
        }
      });
    },
    async SubmitSave() {
      const _this = this;
      try {
        let Topic = "";
        let i = 1;
        let gettype = Object.prototype.toString;
        if (
          _this.obj.Topic &&
          gettype.call(_this.obj.Topic) === "[object Array]"
        ) {
          _this.obj.Topic.map((item) => {
            if (i === 1) {
              Topic = item;
              i = i + 1;
            } else {
              Topic = Topic + "," + item;
            }
          });
        }
        let objNew = JSON.parse(JSON.stringify(this.obj));
        objNew.Topic = Topic;
        if (objNew.Service && objNew.Service.length) {
          objNew.Service = objNew.Service.join(",");
        }
        if (objNew.Limit && objNew.Limit.length) {
          objNew.Limit = objNew.Limit.join(",");
        }
        objNew.SaleBeginDate = objNew.SaleBeginDate
          ? dayjs(objNew.SaleBeginDate).format("YYYY-MM-DD HH:mm:ss")
          : "";
        objNew.SaleEndDate = objNew.SaleEndDate
          ? dayjs(objNew.SaleEndDate).format("YYYY-MM-DD HH:mm:ss")
          : "";
        const fetchData = async () => {
          const res = await api.SaveProduct(objNew);
          if (res.Status === 100) {
            _this.$Message.success("保存成功");
            _this.saveLoading = false;
            _this.isShow = false;
            _this.CheckModel = false;

            _this.$emit("on-save", res.Data);
            _this.$importCache("AllProduct", this.$data, null, false);
          } else {
            this.$Message.error(res.Msg);
            _this.saveLoading = false;
          }
        };
        if (this.obj.SubProductList && this.obj.SubProductList.length) {
          let flag = true;
          for (let i = 0; i < this.obj.SubProductList.length; i++) {
            if (
              !this.obj.SubProductList[i].Model ||
              !this.obj.SubProductList[i].Stock
            ) {
              flag = false;
              break;
            }
          }
          if (flag) {
            objNew.SubProductList = this.obj.SubProductList.map(
              (item, index) => ({
                ...item,
                ...Omit(objNew, ["ID", "SubProductList", "ParentID"]),
                Model: item.Model,
                Stock: item.Stock,
                Code: `${objNew.Code}-${index}`,
                Name: `${objNew.Name}-${item.Model}`,
              })
            );
            console.log(objNew);
            fetchData();
          } else {
            _this.$Message.error("请完整填写商品规格信息");
          }
        } else {
          fetchData();
        }
      } catch (error) {
        this.$Notice.error({
          title: "错误",
          desc: error,
        });
        this.tableLoading = false;
        _this.saveLoading = false;
        // TODO: 记录错误日志
      }
    },
    brandSelectChange(value, keepValue) {
      const _this = this;
      _this.modelNewClass = [];
      if (_this.obj) {
        _this.obj.BrandID = value;
        _this.ProductClassInfo.map((item) => {
          if (item.BrandID) {
            const TempList = item.BrandID.split(",");
            TempList.forEach((cell) => {
              const BrandID = parseInt(cell);
              if (BrandID === parseInt(value)) {
                _this.modelNewClass.push(item);
              }
            });
          }
        });
        if (!keepValue) {
          _this.obj.ClassCode = null;
          _this.ClassCode = [];
        }
      }
    },
    stockNumberChange(key, val) {
      this.Default = val;
    },
    stockNumberFocus(key, row) {
      this.Default = row[key];
    },
    stockNumberBlur(key, row) {
      let temp = this.obj.ListBasicProductMapping.find((item) => {
        return item.BasicProductID === row.BasicProductID;
      });
      temp[key] = this.Default;
    },
    addSubProduct() {
      if (!this.obj.SubProductList) {
        this.$set(this.obj, "SubProductList", [{ Model: "", Stock: 0 }]);
      } else {
        this.$set(this.obj.SubProductList, this.obj.SubProductList.length, {
          Model: "",
          Stock: 0,
        });
      }
    },
    onDelSubProduct(row, index) {
      if (row.ID) {
        api.DeleteProduct({ ids: [row.ID] }).then(() => {
          this.obj.SubProductList.splice(index, 1);
        });
      } else {
        this.obj.SubProductList.splice(index, 1);
      }
    },
    handleModelInput(value, index) {
      this.obj.SubProductList[index].Model = value;
    },
    handleStockInput(value, index) {
      this.obj.SubProductList[index].Stock = value;
    },
  },
  computed: {
    token() {
      return this.$store.state.app.qiniuToken;
    },
    UploadType() {
      return bizEnum.UploadType;
    },
    ProductBrandInfo() {
      return this.$store.state.product.brandList;
    },
    ProductClassInfo() {
      return this.$store.state.product.classList;
    },
    ProductStatusEnum() {
      return bizEnum.ProductStatusEnum;
    },
    ProductTopicEnum() {
      return bizEnum.ProductTopicEnum;
    },
    ProductServiceEnum() {
      return bizEnum.ProductServiceEnum;
    },
    ProductLimitEnum() {
      return bizEnum.ProductLimitEnum;
    },
  },
  components: { IUpload, TinymceEditor },
};
</script>

<style lang="less">
.tiny-parent .tox-tinymce {
  height: 600px !important;
}

.product-img-upload {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .video-box {
    width: 308px !important;
    height: auto;
    position: relative;
    list-style: none;
    margin-right: 5px;

    .file-item-cover {
      position: absolute;
      width: 150px;
      height: 150px;
      text-align: right;
      top: 0;
      left: 0;
      display: none;
      justify-content: flex-end;
      align-items: flex-start;
      border: 1px dashed #dcdee2;
      border-radius: 4px;

      & > i {
        cursor: pointer;
        position: relative;
        top: 0px;
        right: 2px;
        z-index: 999;
      }
    }

    &:hover .file-item-cover {
      display: flex;
    }

    .files-list {
      height: 150px !important;
    }

    video {
      width: 150px;
      height: 150px;
    }
  }
}

#tranuplode {
  .i-upload-container {
    .upload-btn {
      width: 150px;
      height: 150px;
    }
  }
}
</style>
